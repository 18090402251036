<template>
  <div class="footer">
    <div class="flow_section">
      <div class="left_section">
        <div class="logo">
          <img src="@/assets/images/logo_white.svg" alt="" />
        </div>
        <div class="text">
          KollJobs is an online platform that enables freelance call center
          agents to earn more while working flexible hours from anywhere.
        </div>
      </div>
      <div class="right_section">
        <div class="email">hello@kollcorp.com</div>
        <router-link class="link" to="/privacy_policy"
          >Privacy policy</router-link
        >
        <router-link class="link" to="/terms_of_use">Terms of use</router-link>
      </div>
    </div>
    <div class="copy_right">© Koll Corp Services. All rights reserved.</div>
  </div>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped>
.footer {
  @apply w-full h-auto bg-customGray-dark py-20 px-5;
  @apply md:px-10;
  @apply lg:px-20;
}
.flow_section {
  @apply flex flex-col w-full max-w-sm;
  @apply sm:flex-row sm:max-w-full sm:justify-between;
}
.text {
  @apply font-montserrat mt-12 font-semibold text-sm text-white;
  @apply lg:max-w-xl;
  @apply md:max-w-lg;
  @apply sm:max-w-sm;
}
.right_section {
  @apply mt-32;
  @apply lg:mt-28 lg:mr-20;
  @apply md:mt-24 md:mr-12;
  @apply sm:mt-24;
}
.email {
  @apply font-montserrat text-lg text-white;
  @apply sm:text-base;
  @apply md:text-lg;
}
.link {
  @apply font-montserrat text-lg text-white mt-3 cursor-pointer hover:underline block;
  @apply lg:mt-4;
  @apply sm:text-base;
  @apply md:text-lg;
}
.link.router-link-exact-active {
  @apply underline;
}
.copy_right {
  @apply font-montserrat text-sm text-white mt-24;
  @apply sm:mt-0;
}
</style>
