<template>
  <div class="testimonials_section">
    <div class="flow_section">
      <div class="block">
        <div class="image">
          <img src="@/assets/images/madhurima.jpg" alt="" />
        </div>
        <div class="personal_info">
          <div class="name">Madhurima</div>
          <div class="tag">Homemaker / Koll Agent</div>
        </div>
        <div class="testimonial">
          “KollJobs allowed me to return to gainful employment on my own terms.”
        </div>
      </div>
      <br class="mt-16 sm:hidden" />
      <div class="block">
        <div class="image">
          <img src="@/assets/images/rajiv.jpg" alt="" />
        </div>
        <div class="personal_info">
          <div class="name">Rajiv</div>
          <div class="tag">Retiree / Koll Agent</div>
        </div>
        <div class="testimonial">
          “I feel productive again. I can work when I want and the pay is
          great.”
        </div>
      </div>
      <br class="mt-16 sm:hidden" />
      <div class="block">
        <div class="image">
          <img src="@/assets/images/akanksha.jpg" alt="" />
        </div>
        <div class="personal_info">
          <div class="name">Akanksha</div>
          <div class="tag">Fresher / Koll Agent</div>
        </div>
        <div class="testimonial">
          “I was finding it hard to get a job in this economy. KollJobs has been
          a lifesaver.”
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestimonialsSection"
};
</script>

<style scoped>
.testimonials_section {
  @apply w-full min-h-screen py-20 bg-customGray-extralight flex flex-col justify-center items-center px-5;
  @apply md:px-10;
}
.flow_section {
  @apply flex flex-col justify-center items-center w-full max-w-sm;
  @apply sm:max-w-full sm:flex-row sm:items-start sm:w-full sm:justify-between;
  @apply lg:justify-center;
}
.block {
  @apply flex flex-col items-center text-center;
  @apply lg:max-w-xs lg:mx-10;
}
.image {
  @apply w-36 h-36 rounded-full bg-white border border-customGray overflow-hidden;
  @apply sm:w-20 sm:h-20;
  @apply md:w-28 md:h-28;
  @apply lg:w-36 lg:h-36;
}
.personal_info {
  @apply mt-6;
}
.name {
  @apply font-montserrat font-medium text-2xl;
  @apply sm:text-lg;
  @apply md:text-xl;
  @apply lg:text-xl;
}
.tag {
  @apply font-montserrat text-sm mt-2;
}
.join_date {
  @apply font-montserrat text-xs text-customGray-dark;
}
.testimonial {
  @apply font-montserrat text-xl text-left mt-8;
  @apply sm:text-lg sm:px-3;
  @apply md:px-5;
  @apply lg:text-xl lg:px-5;
}
</style>
