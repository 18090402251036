<template>
  <base-button class="sign_in_button">
    <div class="hidden sm:block">
      SIGN IN
    </div>
    <img
      src="@/assets/images/lock.svg"
      alt=""
      class="sm:hidden  fill-current"
    />
  </base-button>
</template>

<script>
import BaseButton from "./BaseButton.vue";
export default {
  name: "SignUpButton",
  components: {
    "base-button": BaseButton
  }
};
</script>

<style scoped>
.sign_in_button {
  @apply border border-mainPurple rounded px-3 py-2.5 font-montserrat focus:outline-none;
  @apply sm:py-3 sm:px-5;
  @apply hover:bg-mainPurple hover:text-white active:bg-mainPurple-dark active:text-white active:outline-none;
  -webkit-tap-highlight-color: transparent; /* for removing the highlight */
}
</style>
