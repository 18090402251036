<template>
  <button @click="onClickButton">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "BaseButton",
  methods: {
    onClickButton() {
      // emit event
      this.$emit("onClick");
    }
  }
};
</script>

<style scoped>
.base_button {
}
</style>
