<template>
  <div class="how_it_works_section">
    <div class="main_heading">How it <br class="sm:hidden" />works</div>
    <div class="flow_section">
      <div class="block">
        <div class="icon_section">
          <img src="@/assets/images/star.svg" alt="" class="icon" />
        </div>
        <div class="block_heading heading_one">
          Create profile and get rated
        </div>
      </div>
      <img src="@/assets/images/arrow.svg" alt="" class="direction" />
      <div class="block">
        <div class="icon_section">
          <img src="@/assets/images/train_person.svg" alt="" class="icon" />
        </div>
        <div class="block_heading">Pick job, train, and qualify</div>
      </div>
      <img src="@/assets/images/arrow.svg" alt="" class="direction" />
      <div class="block">
        <div class="icon_section">
          <img src="@/assets/images/clock.svg" alt="" class="icon" />
        </div>
        <div class="block_heading heading_one">
          Log in when you wish
        </div>
      </div>
      <img src="@/assets/images/arrow.svg" alt="" class="direction" />
      <div class="block">
        <div class="icon_section">
          <img src="@/assets/images/iphone.svg" alt="" class="icon" />
        </div>
        <div class="block_heading heading_one">
          Handle calls and get paid
        </div>
      </div>
    </div>
    <div class="button_section">
      <sign-up />
    </div>
  </div>
</template>

<script>
import SignUp from "@/components/Button/SignUp";
export default {
  name: "HowItWorksSection",
  components: {
    "sign-up": SignUp
  }
};
</script>

<style scoped>
.how_it_works_section {
  @apply w-full min-h-screen flex flex-col justify-center items-center py-20 px-5;
}
.button_section {
  @apply w-full flex justify-center mt-20;
  @apply lg:mt-16;
}
.flow_section {
  @apply flex flex-col mt-20 items-center justify-center w-full;
  @apply sm:flex-row sm:items-start sm:justify-evenly sm:w-4/5;
  @apply lg:mt-16 lg:w-3/4;
}
.block {
  @apply w-auto h-auto flex flex-col items-center;
  @apply sm:w-20;
  @apply lg:w-36;
}
.icon_section {
  @apply w-28 h-28 rounded-full bg-customLightBlue flex justify-center items-center;
  @apply sm:w-20 sm:h-20;
  @apply lg:w-36 lg:h-36;
}
.icon {
  @apply h-1/2 w-auto;
}
.main_heading {
  @apply font-montserrat text-5xl font-semibold;
  @apply md:text-6xl;
  @apply lg:text-7xl;
}
.block_heading {
  @apply font-montserrat font-semibold text-center mt-8 text-lg;
  @apply sm:text-sm sm:mt-6;
  @apply lg:text-lg lg:mt-6;
}
.direction {
  @apply w-8 h-auto my-10 transform rotate-90;
  @apply sm:rotate-0 sm:w-5 sm:my-0 sm:mt-8;
  @apply lg:my-0 lg:mt-14 lg:w-8;
}
</style>
