<template>
  <base-button class="signup_button" @onClick="onClickSignUp">
    SIGN UP
  </base-button>
</template>

<script>
import BaseButton from "./BaseButton.vue";
export default {
  name: "SignUpButton",
  components: {
    "base-button": BaseButton
  },
  methods: {
    onClickSignUp() {
      window.location.href =
        "https://docs.google.com/forms/d/e/1FAIpQLSdcnDqFmVHn_0hLoj520nQx_hiOb-3bOV5sf4Al8vL0zbKAaA/viewform";
    }
  }
};
</script>

<style scoped>
.signup_button {
  @apply bg-mainPurple px-5 py-3 w-full max-w-sm font-montserrat font-medium text-2xl text-white focus:outline-none;
  @apply active:text-white hover:bg-mainPurple-dark active:bg-mainPurple-darker active:outline-none;
  border-radius: 400px;
  -webkit-tap-highlight-color: transparent; /* for removing the highlight */
}
</style>
