<template>
  <div class="base_header ">
    <div class="logo_section">
      <img src="@/assets/images/logo.svg" class="logo" @click="onClickLogo" />
    </div>
    <div class="button_section">
      <sign-in />
    </div>
  </div>
</template>

<script>
import SignIn from "@/components/Button/SignIn";

export default {
  name: "BaseHeader",
  props: {
    button: {
      type: String,
      default: "SignIn"
    }
  },
  components: {
    "sign-in": SignIn
  },
  methods: {
    onClickLogo() {
      // go to home screen if its not home screen
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    }
  }
};
</script>

<style scoped>
.base_header {
  @apply h-auto w-full bg-white px-5 py-5 border-b border-customGray-light flex flex-row items-center justify-between;
  @apply sm:px-5 sm:py-5;
  @apply md:px-10 md:py-8;
}
.logo {
  @apply h-8 w-auto cursor-pointer;
  @apply md:h-10;
}
</style>
