<template>
  <div class="earn_section">
    <div class="heading_one">Earn <br class="sm:hidden" />up to</div>
    <div class="heading_two">10x</div>
    <div class="heading_three">more</div>
  </div>
</template>

<script>
export default {
  name: "EarnSection",
  methods: {}
};
</script>

<style scoped>
.earn_section {
  @apply min-h-screen w-full flex flex-col justify-center items-center text-center px-5;
}
.heading_one {
  @apply font-montserrat font-semibold text-7xl;
}
.heading_two {
  @apply font-montserrat font-semibold text-8xl text-customLightBlue py-5 md:py-0;
  font-size: 170px;
}
.heading_three {
  @apply font-montserrat font-semibold text-7xl;
}
@media (min-width: 768px) {
  .heading_one {
    font-size: 100px;
  }
  .heading_two {
    font-size: 200px;
  }
  .heading_three {
    font-size: 100px;
  }
}
@media (min-width: 1024px) {
  .heading_one {
    font-size: 140px;
  }
  .heading_two {
    font-size: 280px;
  }
  .heading_three {
    font-size: 140px;
  }
}
</style>
