<template>
  <div class="home">
    <div class="w-screen fixed z-50">
      <Header />
    </div>

    <div id="content">
      <main-section />
      <work-from-anywhere-section />
      <earn-section />
      <earning-estimator-section />
      <how-it-works-section />
      <testimonials-section />
      <Footer />
    </div>
  </div>
</template>

<script>
import BaseHeader from "@/components/Header/BaseHeader";
import MainSection from "@/components/MainSection";
import WorkFromAnywhereSection from "@/components/WorkFromAnywhereSection";
import EarnSection from "@/components/EarnSection";
import HowItWorksSection from "@/components/HowItWorksSection";
import TestimonialsSection from "@/components/TestimonialsSection";
import Footer from "@/components/Footer";
import EarningEstimatorSection from "@/components/EarningEstimatorSection";

export default {
  name: "Home",
  components: {
    Header: BaseHeader,
    "main-section": MainSection,
    "work-from-anywhere-section": WorkFromAnywhereSection,
    "earn-section": EarnSection,
    "how-it-works-section": HowItWorksSection,
    "testimonials-section": TestimonialsSection,
    "earning-estimator-section": EarningEstimatorSection,
    Footer
  }
};
</script>

<style scoped></style>
