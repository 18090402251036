<template>
  <div class="main_section" :style="mainSectionStyle">
    <div class="sub_heading">Answer calls from home</div>
    <div class="main_heading">Flexible hours. More pay.</div>
    <div class="button_section">
      <sign-up-button />
    </div>
  </div>
</template>

<script>
import SignUp from "@/components/Button/SignUp";
export default {
  name: "MainSection",
  data() {
    return {
      windowHeight: window.innerHeight
    };
  },
  components: {
    "sign-up-button": SignUp
  },
  computed: {
    mainSectionStyle() {
      return {
        height: `${this.windowHeight}px`,
        width: `100%`
      };
    }
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
    }
  },
  mounted() {
    // on window resize
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  }
};
</script>

<style scoped>
.main_section {
  @apply flex flex-col justify-center items-center text-center px-5 pt-20 md:px-10 lg:px-0;
}
.sub_heading {
  @apply font-montserrat font-normal text-4xl text-customLightBlue;
  @apply sm:text-3xl;
  @apply md:text-4xl;
  @apply lg:text-5xl;
}
.main_heading {
  @apply font-montserrat font-semibold text-6xl mt-8;
  @apply sm:text-5xl sm:mt-5;
  @apply md:text-6xl md:mt-8;
  @apply lg:text-7xl;
}
.button_section {
  @apply w-full mt-16 sm:mt-12 md:mt-16;
}
</style>
