import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "@/assets/css/styles.css";
import VueHead from "vue-head";

createApp(App)
  .use(router)
  .use(store)
  .use(VueHead)
  .mount("#app");
