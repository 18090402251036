<template>
  <div class="increment_decrement">
    <button class="control" @click="onClickDecrement">
      <img src="@/assets/images/minus.svg" alt="" class="control_icon" />
    </button>
    <div class="value">
      {{ value }}
    </div>
    <button class="control" @click="onClickIncrement">
      <img src="@/assets/images/plus.svg" alt="" class="control_icon" />
    </button>
  </div>
</template>

<script>
export default {
  name: "IncrementDecrement",
  data() {
    return {
      value: this.default
    };
  },
  props: {
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    default: {
      type: Number
    }
  },
  methods: {
    onClickIncrement() {
      if (this.value >= this.min && this.value < this.max) {
        this.value++;
        this.$emit("onChange", this.value);
      }
    },
    onClickDecrement() {
      if (this.value > this.min && this.value <= this.max) {
        this.value--;
        this.$emit("onChange", this.value);
      }
    }
  }
};
</script>

<style scoped>
.increment_decrement {
  @apply w-auto h-auto flex flex-row my-5;
}
.control {
  @apply focus:outline-none active:outline-none rounded w-12 h-12 bg-customLightBlue font-montserrat font-light text-6xl flex flex-col justify-center items-center text-white active:bg-customLightBlue-darker hover:bg-customLightBlue-dark;
  -webkit-tap-highlight-color: transparent; /* for removing the highlight */
}
.control_icon {
  @apply w-1/2 h-auto;
}
.value {
  @apply rounded w-12 h-12 mx-3 font-montserrat flex flex-col justify-center items-center bg-white font-semibold text-2xl border border-customLightBlue;
}
</style>
