<template>
  <div class="earning_estimator_section">
    <div class="main_heading">Earning estimator</div>
    <div class="flow_section">
      <div class="first">
        <div class="section_heading">AGENT RATING</div>
        <slider-selector @onChange="getRating" />
        <div class="section_description">
          Rating is based on assessment tests and caller feedback
        </div>
      </div>
      <div class="middle">
        <div class="block first_block">
          <div class="section_heading">DAILY WORK HOURS</div>
          <increment-decrement
            key="0"
            :min="1"
            :max="8"
            :default="4"
            @onChange="onChangeHours"
          />
          <div class="section_description special">Hours per day</div>
        </div>
        <div class="block">
          <div class="section_heading">TALKTIME PER CALL</div>
          <increment-decrement
            key="1"
            :min="1"
            :max="15"
            :default="5"
            @onChange="onChangeTalktime"
          />
          <div class="section_description special">
            Minutes per call, excluding time spent on updating notes
          </div>
        </div>
      </div>
      <div class="last">
        <div class="section_heading">YOU COULD EARN UP TO</div>
        <span class="my-10" />
        <div class="earning"><span class="rupees">₹</span>{{ earning }}</div>
        <div class="section_sub_heading">Per month</div>
        <div class="section_description special">(25-day month)</div>
      </div>
    </div>
  </div>
</template>

<script>
import SliderSelector from "@/components/SliderSelector";
import IncrementDecrement from "@/components/IncrementDecrement";
import currency from "currency.js";

export default {
  name: "EarningEstimatorSection",
  data() {
    return {
      rating: 2,
      hours: 4,
      talktime: 5
    };
  },
  computed: {
    earning() {
      // agent rating renumeration mapping
      let renumerationPerMinute = {
        1: 3.81,
        2: 7.63,
        3: 11.44,
        4: 15.25,
        5: 19.07,
        6: 22.88,
        7: 26.69,
        8: 30.51,
        9: 34.32,
        10: 38.14
      };

      return currency(
        Math.floor(
          25 *
            (renumerationPerMinute[this.rating] *
              this.talktime *
              Math.floor(60 / (this.talktime + 2)) *
              this.hours)
        ),
        { symbol: "", useVedic: true, precision: 0 }
      ).format();
    }
  },
  components: {
    "slider-selector": SliderSelector,
    "increment-decrement": IncrementDecrement
  },
  methods: {
    getRating(value) {
      this.rating = parseInt(value);
    },
    onChangeHours(value) {
      this.hours = parseInt(value);
    },
    onChangeTalktime(value) {
      this.talktime = parseInt(value);
    }
  }
};
</script>

<style scoped>
.earning_estimator_section {
  @apply w-full min-h-screen flex flex-col bg-customLightBlue-light bg-opacity-60 justify-center items-center text-center px-5 py-20;
  @apply md:px-10;
  @apply lg:px-20;
}
.main_heading {
  @apply font-montserrat text-5xl font-semibold;
  @apply md:text-6xl;
  @apply lg:text-7xl;
}
.flow_section {
  @apply w-full h-auto flex flex-col justify-center items-center mt-16;
  @apply sm:flex-row sm:justify-evenly;
}
.first {
  @apply text-center flex flex-col items-center;
}
.middle {
  @apply mt-2 flex flex-col items-center text-center;
  @apply sm:mt-0;
}
.block {
  @apply flex flex-col items-center mt-8;
}
.first_block {
  @apply sm:mt-0 !important;
}
.last {
  @apply mt-8 flex flex-col items-center;
  @apply sm:mt-0;
}
.section_heading {
  @apply font-montserrat font-semibold text-xl;
  @apply sm:text-base;
  @apply md:text-lg;
  @apply lg:text-xl;
}
.section_description {
  @apply font-montserrat text-xs text-customGray-darker text-center mt-3;
  max-width: 300px;
}
@media (min-width: 638px) {
  .section_description {
    max-width: 150px;
  }
}
.special {
  @apply mt-0 sm:mt-0 !important;
}
.block .section_description {
  max-width: 200px;
}
.earning {
  @apply font-montserrat font-semibold text-6xl my-5 absolute mt-9;
  @apply sm:text-4xl;
  @apply md:text-5xl;
  @apply lg:text-6xl;
}
.section_sub_heading {
  @apply font-montserrat;
}
.rupees {
  @apply font-medium text-3xl mr-2;
}
</style>
