<template>
  <div class="slider_selector">
    <Slider
      :min="1"
      :max="10"
      :orientation="orientation"
      v-model="value"
      class="slider-custom"
      :tooltips="true"
      :direction="direction"
    />
  </div>
</template>

<script>
import Slider from "@vueform/slider";

export default {
  name: "SliderSelector",
  // register the component
  components: { Slider },
  data() {
    return {
      value: 2,
      width: window.innerWidth
    };
  },
  watch: {
    value() {
      this.$emit("onChange", this.value);
    }
  },
  computed: {
    orientation() {
      if (this.width < 638) {
        return "horizontal";
      } else {
        return "vertical";
      }
    },
    direction() {
      if (this.width < 638) {
        return "ltr";
      } else {
        return "rtl";
      }
    }
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
    }
  },
  mounted() {
    // emit value 1
    this.$emit("onChange", this.value);
    // on window resize
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  }
};
</script>

<style src="@/assets/css/slider.css"></style>
<style scoped>
.slider_selector {
  @apply mb-5 mt-12;
  @apply sm:mt-5 sm:mb-5;
}
</style>
