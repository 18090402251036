<template>
  <div class="work_from_anywhere_section">
    <div class="main_heading">
      Work from anywhere
    </div>
    <div class="sub_heading">
      You will need:
    </div>
    <div class="images_section">
      <div class="image_block">
        <img src="@/assets/images/internet.svg" alt="" class="icon" />
        <div class="block_heading">Internet</div>
      </div>
      <div class="image_block">
        <img src="@/assets/images/mobile.svg" alt="" class="icon" />
        <div class="block_heading">Mobile / Landline</div>
      </div>
      <div class="image_block">
        <img src="@/assets/images/pc.svg" alt="" class="icon" />
        <div class="block_heading">PC / Laptop</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkFromAnywhereSection"
};
</script>

<style scoped>
.work_from_anywhere_section {
  @apply w-full min-h-screen bg-customGray-extralight flex flex-col py-20 px-5 justify-center items-center text-center;
}
.main_heading {
  @apply font-montserrat text-5xl font-semibold;
  @apply md:text-6xl;
  @apply lg:text-7xl;
}
.sub_heading {
  @apply font-montserrat text-2xl mt-6;
  @apply md:mt-8 md:text-3xl;
  @apply lg:text-4xl;
}
.images_section {
  @apply flex flex-col items-center justify-center w-3/4;
  @apply sm:flex-row sm:justify-between;
  @apply md:w-2/3;
  @apply lg:w-1/2;
}
.image_block {
  @apply flex flex-col items-center mt-12;
  @apply md:mt-16;
  @apply lg:mt-20;
}
.block_heading {
  @apply font-montserrat text-xl mt-4 font-semibold;
  @apply md:mt-5;
  @apply lg:mt-8;
}
.icon {
  @apply h-20 w-auto;
  @apply md:h-24;
  @apply lg:h-28;
}
</style>
